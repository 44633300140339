<template>
  <section class="section">
    <div class="container">
      <div class="mb-6 has-text-centered">
        <span class="has-text-grey-dark">Permission Denied</span>
        <h2 class="mt-2 mb-3 is-size-1 is-size-3-mobile has-text-weight-bold">Nincs jogod az oldal megtekintéséhez!</h2>
        <p class="subtitle has-text-grey mb-5">Ha úgy véled hiba történt próbáld meg újra, vagy írj üzenetet.</p>
        <div class="buttons is-centered">
          <a class="button is-primary"
             href=""
             @click.prevent="$router.push('/')">Vissza a főoldalra</a>
<!--          <a class="button"-->
<!--             href=""-->
<!--             @click.prevent="$router.go()">Újratölt</a>-->
        </div>
      </div>
      <div class="columns is-multiline is-centered">
        <div class="column is-8">
          <!--suppress HtmlUnknownTarget -->
          <img class="image is-fullwidth"
               src="/img/permission-denied.png"
               alt="Page not found">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'NoPermission',
}
</script>

<style scoped>

</style>
